interface IForm {
  [k: string]: string | number | File;
}

interface Model {
  properties: [];
  optional: string | undefined;
  type: string;
}

const isFormValid = (
  formData: IForm,
  formErrors: IForm,
  model: Model,
  file
) => {
  if (!model) return false;

  const { properties } = model;

  if (!Object.values(formErrors).every((error) => !error)) {
    return false;
  }

  return Object.keys(properties).every((field) => {
    const { optional, type } = properties[field];

    if (optional) return true;

    if (type.includes("file")) {
      const fileInput = file[field];
      if (fileInput && fileInput.isCapturing && fileInput.isCapturing()) {
        return true;
      }
    }

    if (type.includes("number") || type.includes("integer")) {
      if (formData[field] === "") {
        formData[field] = 0;
      }
      return true;
    }

    return formData[field] !== undefined && formData[field] !== "";
  });
};

export default isFormValid;
