import { Stripe } from "@stripe/stripe-js";
import { addFunds } from "../../../api/finances";
import { toastError, toastSuccess } from "../../../utils/toasts";

export async function payment (amount: number, user: { username: string; }, stripe: Stripe | null, paymentMethod: any) {
    if (!amount || isNaN(amount) || amount <= 0) {
        toastError("Please enter a valid amount");
        return;
    }
    try {
        const { client_secret } = await addFunds(user.username, { amount });

        const result = await stripe?.confirmCardPayment(client_secret, {
            payment_method: paymentMethod
        });
        if (result?.error) throw result.error;
        toastSuccess("Payment successful!");
        // onSuccess();
    } catch (error) {
        console.error("Payment error:", error);
        toastError("The payment could not be made. Try again.");
    }
}