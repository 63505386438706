import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../api/users";
import {
  deleteActiveUser,
  setActiveUser,
  updateRequestUser,
} from "../../store/actions/user";
import NavBar from "./NavBar/index.tsx";

function Header() {
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  if (sessionStorage.getItem("root") == null) {
    const tabId = window.name || Math.random().toString(36).substring(2, 15);
    window.name = tabId;
    sessionStorage.setItem("tabId", tabId);
  }

  let activeUser;

  const activeUsers = useSelector((state) => state.user.activeUser);
  // console.log(Array.isArray(activeUsers));
  // activeUser = Array.isArray(activeUsers)
  //   ? activeUsers.find((e) => e.id === window.name)
  //   : null;

  if (!Array.isArray(activeUsers)) {
    dispatch(setActiveUser({}));
  } else {
    activeUser = activeUsers.find((e) => e.id === window.name);
  }

  // useEffect(() => {
  //   let isPageReloading = false;

  //   // if (performance.navigation.type === performance.navigation.TYPE_RELOAD) {
  //   //   isPageReloading = true;
  //   // }

  //   const handleBeforeUnload = (event) => {
  //     if (sessionStorage.getItem("tabId") === window.name && !isPageReloading) {
  //       sessionStorage.removeItem("tabId");
  //       dispatch(deleteActiveUser(window.name));
  //     }
  //   };

  //   const handleVisibilityChange = () => {
  //     isPageReloading = document.visibilityState !== "hidden";
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   document.addEventListener("visibilitychange", handleVisibilityChange);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //     document.removeEventListener("visibilitychange", handleVisibilityChange);
  //   };
  // }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      // activeUser = activeUsers.find((e) => e.id === window.name);
      try {
        if (user) {
          const originalUser = await getUser(user.username);
          if (originalUser) {
            dispatch(updateRequestUser(originalUser));
          }
          if (activeUsers.length == 0 || !activeUser) {
            dispatch(setActiveUser(originalUser));
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <header>
      <NavBar />
    </header>
  );
}

export default Header;
