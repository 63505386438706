import { createTeam, getUser, inviteMember } from "../../api/users";
import { toastError } from "../../utils/toasts";

export const loginSuccess = (userData) => ({
  type: "LOGIN_SUCCESS",
  payload: userData,
});

export const updateRequestUser = (userData) => ({
  type: "UPDATE_USER",
  payload: userData,
});

export const logout = () => ({ type: "USER_LOGOUT" });

export const setActiveUser = (userData) => ({
  type: "SET_ACTIVE_USER",
  payload: userData,
});

export const deleteActiveUser = (id) => ({
  type: "DELETE_ACTIVE_USER", payload: id
});

export const switchActiveUser = (username) => async (dispatch, getState) => {
  try {
    const { user, activeUser } = getState().user;
    if (username === activeUser.username) {
      return;
    } else if (username === user.username) {
      window.location.href = `/${user.username}`;
      dispatch(setActiveUser(user));
    } else {
      const userData = await getUser(username);
      if (userData) {
        window.location.href = `/${userData.username}`;
        dispatch(setActiveUser(userData));
      } else {
        toastError("User not found", Math.floor(Math.random() * 1000000));
        window.location.href = `/${user.username}`;
        dispatch(setActiveUser(user));
      }
    }
  } catch (error) {
    console.error("Error switching active user:", error);
    toastError("Error switching user", Math.floor(Math.random() * 1000000));
  }
};

export const createTeamAction = (teamData) => async (dispatch) => {
  try {
    const createdTeam = await createTeam(teamData);
    const updatedUser = await getUser(createdTeam.team_founder);
    const newActiveUser = await getUser(createdTeam.username);
    dispatch(loginSuccess(updatedUser));
    dispatch(switchActiveUser(newActiveUser.username));
  } catch (error) {
    toastError(
      `Error creating team: ${JSON.stringify(error)}`,
      Math.floor(Math.random() * 1000000)
    );
  }
};
