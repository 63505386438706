import React, { useState } from "react";
import { Badge, Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./type";
import { Link as LK, useLocation, useNavigate } from "react-router-dom";
import { Avatar } from "@nextui-org/react";
import { switchActiveUser, logout } from "../../../store/actions/user";
import CreateTeamModal from "../../Profile/Modals/CreateTeamModal";
import { useResize } from "../../../hooks/useresize.ts";

import "./NavBar.module.css";
import styles from "./NavBar.module.css";

//images
import Logo from "../images/logo.js";
import fmava from "../NavBar/images/fmava.jpeg";
import vector from "./images/Vector 912.png";
import { LINKS } from "../../../utils/constants.js";

function NavBar() {
  const activeUser = (useSelector((state: RootState) =>
    state.user.activeUser.find((user) => user.id === window.name)
  ))?.user;
  const location = useLocation();
  const isActive = (path: string) => location.pathname === path;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user.user);
  const [createTeamModalOpen, setCreateTeamModalOpen] = useState(false);
  const width = useResize();

  const loc = [
    "/explore",
    "/",
    `/${user?.username}`,
    `/${user?.teams[0]}`,
    "/profile",
    "/explore",
    "/signup",
    "/terms",
    "/privacy",
    "/login",
    "/signup",
  ];

  const handleMenuAction = (key: string) => {
    switch (key) {
      case "logout":
        dispatch(logout());
        navigate("/");
        break;
      case "profile":
        navigate("/profile");
        break;
      case "finances":
        navigate("/finances");
        break;
      case "create-team":
        openCreateTeamModal();
        break;
      default:
        dispatch(switchActiveUser(key, navigate));
    }
  };

  const openCreateTeamModal = () => {
    setCreateTeamModalOpen(true);
  };
  const closeCreateTeamModal = () => {
    setCreateTeamModalOpen(false);
  };

  const menuItems = user
    ? [
        { key: user?.username, name: user?.username, badge: "personal" },
        ...user?.teams?.map((team) => ({
          key: team,
          name: team,
          badge: "team",
        })),
        { key: "create-team", name: "Create Team" },
        { key: "profile", name: "Profile" },
        { key: "finances", name: "Finances" },
        { key: "logout", name: "Logout" },
      ]
    : [];

  const tabs = activeUser
    ? [
        { name: "Explore", path: "/explore" },
        { name: "My projects", path: `/${activeUser.username}` },
        { name: "Contact us", path: LINKS.CONTACT_US },
        { name: "Discord", path: LINKS.DISCORD },
      ]
    : [
        { name: "Login", path: "/login" },
        { name: "Sign Up", path: "/signup" },
      ];

  return (
    <Navbar
      expand="md"
      collapseOnSelect
      style={{
        background: loc.includes(location.pathname)
          ? "white"
          : "rgba(238,238,238,0.8)",
      }}
    >
      <Container fluid="lg">
        {activeUser && width < 768 ? (
          <NavDropdown
            className={styles.avatar}
            title={
              <Avatar
                src={fmava}
                alt="avatar"
              />
            }
            id="basic-nav-dropdown"
          >
            {menuItems.map((item, index) => (
              <>
                {["create-team", "profile", "logout"].includes(item.key) && (
                  <img
                    src={vector}
                    alt="divider"
                    width={"100%"}
                    height={1}
                    className="pe-0 ps-2"
                  />
                )}
                <NavDropdown.Item
                  key={index}
                  onClick={(_) => handleMenuAction(item.key)}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                    {item.name}
                  </span>
                  <Badge
                    bg="none"
                    className={styles.badge}
                  >
                    {item.badge}
                  </Badge>
                </NavDropdown.Item>
              </>
            ))}
          </NavDropdown>
        ) : (
          ""
        )}
        <Navbar.Brand
          href="/"
          style={{ marginTop: "15px" }}
        >
          <Logo />
        </Navbar.Brand>

        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          className="custom-toggle"
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto me-0">
            {tabs.map((tab, index) => (
              <Nav.Link
                key={index}
                as={LK}
                to={tab.path}
                eventKey={index}
                className={`${styles.link} ${
                  isActive(tab.path) ? styles.active : ""
                }`}
              >
                {tab.name}
              </Nav.Link>
            ))}
          </Nav>
        </Navbar.Collapse>
        {activeUser && width >= 768 ? (
          <Nav>
            <NavDropdown
              className={styles.avatar}
              title={
                <Avatar
                  src={fmava}
                  alt="avatar"
                />
              }
              id="basic-nav-dropdown"
            >
              {menuItems.map((item, index) => (
                <>
                  {["create-team", "profile", "logout"].includes(item.key) && (
                    <img
                      src={vector}
                      alt="divider"
                      width={"100%"}
                      height={1}
                      className="pe-0 ps-2"
                    />
                  )}
                  <NavDropdown.Item
                    key={index}
                    onClick={(_) => handleMenuAction(item.key)}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                      {item.name}
                    </span>
                    <Badge
                      bg="none"
                      className={styles.badge}
                    >
                      {item.badge}
                    </Badge>
                  </NavDropdown.Item>
                </>
              ))}
            </NavDropdown>
          </Nav>
        ) : (
          ""
        )}
      </Container>
      <CreateTeamModal
        isOpen={createTeamModalOpen}
        onClose={closeCreateTeamModal}
      />
    </Navbar>
  );
}

export default NavBar;
