import { store } from "../store/store";
import { refreshToken } from "../api/auth";

function dispatch(type, payload) {
  return {
    type,
    payload,
  };
}

export async function refreshUserToken(token) {
  try {
    const response = await refreshToken(token);
    if (response.data.access) {
      return store.dispatch(
        dispatch("ACCESS_TOKEN_REFRESHED", {
          accessToken: response.data.access,
        })
      );
    } else {
      return await logoutUser();
    }
  } catch (e) {
    return await logoutUser();
  }
}

export async function logoutUser() {
  store.dispatch(dispatch("TOKEN_DESTROYED"));
  store.dispatch(dispatch("USER_LOGOUT"));
}
