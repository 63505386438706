import { useParams } from "react-router-dom";
import React from "react";
import "reactflow/dist/style.css";
import Flow from "../Flow/Flow";
import { useSelector } from "react-redux";

export default function ProjectDetail() {
  const { id, username, projectName } = useParams();
  const requestUser = useSelector((state) =>
    state.user?.activeUser.find((user) => user.id === window.name)
  ).user;
  const isProjectOwner = requestUser?.username === username;
  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <Flow
        projectID={id}
        projectName={projectName}
        username={username}
        isDemo={!isProjectOwner}
      ></Flow>
    </div>
  );
}
