import axios from "axios";
import { store } from "../store/store";
import { logoutUser, refreshUserToken } from "../utils/auth";
import { toastError } from "../utils/toasts";

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const gatewayUrl = process.env.REACT_APP_GATEWAY_URL;
const BASE_URL = `${backendUrl}/api/v1/`;
export const GATEWAY_URL = `${gatewayUrl}/api/v1/`;

export function getBaseUrl() {
  return BASE_URL;
}

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosGatewayInstance = axios.create({
  baseURL: GATEWAY_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use((config) => {
  const { accessToken } = store.getState().auth || {};
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response.data,
  async (error) => {
    const { response } = error;

    if (response) {
      const errorMessage = `Error ${response.status}: ${response.statusText}`;

      if (response.status === 401) {
        if (response.data.code === "token_not_valid") {
          const { refreshToken } = store.getState().auth || {};
          if (refreshToken) {
            await refreshUserToken(refreshToken);
            return axiosInstance(error.config);
          }
        } else {
          await logoutUser();
          return Promise.reject(
            "You have been logged out. Please log in again."
          );
        }
        return Promise.reject("Unexpected error during authentication.");
      }

      if (response.status >= 400 && response.status < 500) {
        switch (response.status) {
          case 400:
            return Promise.reject(
              "The server could not understand the request."
            );
          case 403:
            return Promise.reject(
              "You do not have permission to access this resource."
            );
          case 404:
            return Promise.reject(
              "The requested resource could not be found."
            );
          case 409:
            return Promise.reject(
              "There is a conflict with the current state of the resource."
            );
          case 422:
            return Promise.reject(
              "Unprocessable Entity: The request was well-formed but unable to be followed due to semantic errors."
            );
          case 429:
            return Promise.reject(
              "Too Many Requests: You have sent too many requests in a given amount of time."
            );
          default:
            return Promise.reject(`Client Error: ${errorMessage}`);
        }
      }

      if (response.status >= 500) {
        const serverErrorMessage = "Server Error: Please try again later.";
        toastError(serverErrorMessage);
        return Promise.reject(serverErrorMessage);
      }
    } else if (error.request) {
      const networkErrorMessage =
        "Network Error: Please check your internet connection.";
      toastError(networkErrorMessage);
      return Promise.reject(networkErrorMessage);
    } else {
      const unexpectedErrorMessage =
        "An unexpected error occurred. Please try again later.";
      toastError(unexpectedErrorMessage);
      return Promise.reject(unexpectedErrorMessage);
    }

    const errorData = response?.data || {};
    const detailedErrorMessage =
      errorData.non_field_errors?.[0] || errorData.message || error.statusText;
    const errorId = response
      ? `${response.status}-${detailedErrorMessage}`
      : "unknown-error";
    console.error(`Error ID: ${errorId}, Message: ${detailedErrorMessage}`);
    toastError(detailedErrorMessage, errorId);
    return Promise.reject(detailedErrorMessage);
  }
);

export default axiosInstance;
